import * as i from 'types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Input } from 'common/form';
import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';
import { validation } from 'services';
import { useAuthenticationUser } from 'services/hooks';

import {
  HeaderContainer, StyledForm, ButtonContainer, FormContainer,
  PageWrapper,
} from './styled';

export const RequestForm: React.FC<RequestResetProps> = ({ handleRequestPassword }) => {
  const { t } = useTranslation();
  const { loading } = useAuthenticationUser();
  const { register, handleSubmit, formState: { isValid, errors } } = useForm({
    mode: 'onChange',
  });

  return (
    <PageWrapper>
      <HeaderContainer>
        <Heading as="h2" variant={['gray', 'dark']}>{t('dont worry, we can reset your password')}</Heading>
        <Text variant={['gray', 'dark']}>
          {t('enter your email address that you used to register')}
        </Text>
      </HeaderContainer>
      <StyledForm onSubmit={handleSubmit(handleRequestPassword)}>
        <FormContainer>
          <Input
            label={t('emailaddress')}
            type="email"
            error={errors?.emailaddress}
            placeholder="mail@website.com"
            autoComplete="off"
            {...register('emailaddress', { ...validation.required, ...validation.email })}
          />
        </FormContainer>
        <ButtonContainer>
          <Button
            type="submit"
            size="fullWidth"
            disabled={!isValid || loading}
            $isValid={isValid}
          >
            {t('send')}
          </Button>
        </ButtonContainer>
      </StyledForm>
    </PageWrapper>
  );
};

type RequestResetProps = {
  handleRequestPassword: (data: i.RequestNewPasswordFormData) => void;
};
