import React from 'react';
import { useTranslation, Trans, useI18next } from 'gatsby-plugin-react-i18next';

import { StyledHeading, StyledButton, PageWrapper } from './styled';

export const RequestSuccess: React.FC<RequestSuccessProps> = ({ isSent }) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  return (
    <PageWrapper>
      <StyledHeading as="h2" variant={['gray', 'dark']}>
        <Trans
          defaults="Thanks, we have sent you an email with your username <break />"
          components={{ break: <br /> }}
        />
      </StyledHeading>
      <StyledButton
        type="button"
        size="fullWidth"
        disabled={!isSent}
        $isValid={isSent}
        onClick={() => { navigate('/login'); }}
      >
        {t('return to login page')}
      </StyledButton>
    </PageWrapper>
  );
};

type RequestSuccessProps = {
  isSent: boolean;
};
