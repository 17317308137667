import styled from 'styled-components';

import { media } from 'styles/utils';

export const ArrowPositionContainer = styled.div`
  display: flex;
`;

export const ArrowWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gray.dark};
  padding: 100px 24px 0;
  cursor: pointer;

  ${media.desktop`
    padding: 38px 56px;
  `}
`;
